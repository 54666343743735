import consumer from "./consumer"

consumer.subscriptions.create("StatusChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if( data["message"])
      $("#server-status").html(data["message"])

    const progress = parseInt(data["progress"])
    $("#progress-done").width( "" + progress + "%")
    $("#progress-pending").width(  "" + (100 - progress) +"%")
  }
});